<template>
  <div class="inside-page-content" ref="refPrint">
    <div class="paper all-center">
      <div class="top-row">
        <div class="top-name">{{articleDetail.title}}</div>
        <div class="condition">
          <label>【时间】：{{articleDetail.time}}</label>
          <div class="btns">
            <el-button type="text" icon="el-icon-position" @click="share">分享</el-button>
            <el-button type="text" icon="el-icon-printer" @click="print">打印</el-button>
          </div>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div v-html="articleDetail.content" class="detail-content ql-editor"></div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'noticeDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      notice_id: null,
      articleDetail: {},
    }
  },
  computed: {
  },
  created(options) {
    this.notice_id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    share() {
      let Url = location.href;
      let oInput = document.createElement('input');
      oInput.value = Url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy");
      // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message.success("成功复制链接，请去目标位置粘贴")
    },
    print() {
      this.$nextTick(() => {
        let styleStr = "/printCss/commonPrint.css"
        this.$print(this.$refs.refPrint, styleStr);
      })
    },
    getDetail() {
      this.$api.intellectualProperty.aidDetail(this.notice_id).then((res) => {
        this.articleDetail = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  .top-name {
    font-weight: 700;
  }
  .condition {
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    position: relative;
    .btns {
      position: absolute;
      top: -9px;
      right: 0;
    }
  }
}
.divider {
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content {
  margin: auto;
  width: fit-content;
  white-space: pre-wrap;
}
</style>
